import "../assets/css/vesper/pages/index.scss"
import {useEffect, useState} from "react";
import axiosInstance from "../helpers/axios";
import useDebounce from "../helpers/useDebounce";

const objectsDefault = [
    {
        name: 'Nabokov',
        tags: [
            {
                id: 1,
                name: 'клубный дом'
            },
            {
                id: 2,
                name: 'пентхаусы'
            },
            {
                id: 3,
                name: 'инфраструктура'
            }
        ],
        address: 'Курсовой переулок, 10/1',
        photo: require("../assets/img/ph-1.jpg")
    },
    {
        name: 'Nabokov',
        tags: [
            {
                id: 1,
                name: 'клубный дом'
            },
            {
                id: 2,
                name: 'пентхаусы'
            },
            {
                id: 3,
                name: 'инфраструктура'
            }
        ],
        address: 'Курсовой переулок, 10/1',
        photo: require("../assets/img/ph-1.jpg")
    },
    {
        name: 'Nabokov',
        tags: [
            {
                id: 1,
                name: 'клубный дом'
            },
            {
                id: 2,
                name: 'пентхаусы'
            },
            {
                id: 3,
                name: 'инфраструктура'
            }
        ],
        address: 'Курсовой переулок, 10/1',
        photo: require("../assets/img/ph-1.jpg")
    },
    {
        name: 'Nabokov',
        tags: [
            {
                id: 1,
                name: 'клубный дом'
            },
            {
                id: 2,
                name: 'пентхаусы'
            },
            {
                id: 3,
                name: 'инфраструктура'
            }
        ],
        address: 'Курсовой переулок, 10/1',
        photo: require("../assets/img/ph-1.jpg")
    },
    {
        name: 'Nabokov',
        tags: [
            {
                id: 1,
                name: 'клубный дом'
            },
            {
                id: 2,
                name: 'пентхаусы'
            },
            {
                id: 3,
                name: 'инфраструктура'
            }
        ],
        address: 'Курсовой переулок, 10/1',
        photo: require("../assets/img/ph-1.jpg")
    },
    {
        name: 'Nabokov',
        tags: [
            {
                id: 1,
                name: 'клубный дом'
            },
            {
                id: 2,
                name: 'пентхаусы'
            },
            {
                id: 3,
                name: 'инфраструктура'
            }
        ],
        address: 'Курсовой переулок, 10/1',
        photo: require("../assets/img/ph-1.jpg")
    },
    {
        name: 'Nabokov',
        tags: [
            {
                id: 1,
                name: 'клубный дом'
            },
            {
                id: 2,
                name: 'пентхаусы'
            },
            {
                id: 3,
                name: 'инфраструктура'
            }
        ],
        address: 'Курсовой переулок, 10/1',
        photo: require("../assets/img/ph-1.jpg")
    },
    {
        name: 'Nabokov',
        tags: [
            {
                id: 1,
                name: 'клубный дом'
            },
            {
                id: 2,
                name: 'пентхаусы'
            },
            {
                id: 3,
                name: 'инфраструктура'
            }
        ],
        address: 'Курсовой переулок, 10/1',
        photo: require("../assets/img/ph-1.jpg")
    },
    {
        name: 'Nabokov',
        tags: [
            {
                id: 1,
                name: 'клубный дом'
            },
            {
                id: 2,
                name: 'пентхаусы'
            },
            {
                id: 3,
                name: 'инфраструктура'
            }
        ],
        address: 'Курсовой переулок, 10/1',
        photo: require("../assets/img/ph-1.jpg")
    }
]

function Home() {
    const [mode, setMode] = useState(localStorage.getItem('mode') ?? 'cards');
    const [objects, setObjects] = useState([]);
    const [flQuery, setFlQuery] = useState('');
    const [filtered, setFiltered] = useState(false);
    const [loading, setLoading] = useState(true);
    const [filters, setFilters] = useState({
        finished: -1,
        tags: []
    });
    const [projectsPg, setProjectsPg] = useState({
        total: 0,
        currentPage: 1
    });
    const debouncedFilters = useDebounce(flQuery, 500);

    useEffect(
        () => {
            if (debouncedFilters || filtered) {
                getProjects(1);
            }
        },
        [debouncedFilters]
    );

    useEffect(()=>{
        let query = '';

        if(filters.finished === 1){
            query += 'status=finished' + '&';
        }

        if(filters.finished === 0){
            query += 'status=progress' + '&';
        }

        if(filters.tags.length > 0){
            query += 'tags=' + filters.tags.join(',') + '&';
        }

        if(flQuery && !query){
            setFlQuery(query);
        }else{
            if(query){
                setFlQuery(query);
                setFiltered(true);
            }
        }

    }, [filters]);

    useEffect(()=>{
        localStorage.setItem('mode', mode);
    }, [mode]);

    useEffect(()=>{
        getProjects();
    }, []);

    const getProjects = async(page = 1, category = 'project') => {
        setLoading(true);
        let query = '?category=' + category + '&';
        if(page > 1){
            query += 'page=' + page + '&';
        }

        if(flQuery){
            query += flQuery;
        }

        if(query == '?'){
            query = '';
        }

        try {
            const response = await axiosInstance.get('getObjects' + query);
            if(response.data){
                if(response.data.data){
                    if(page === 1){
                        setObjects(response.data.data);
                    }else{
                        setObjects((objects) => [... new Set([...objects, ...response.data.data])]);
                    }

                    setProjectsPg(response.data.pagination);
                    setTimeout(function (){
                        setLoading(false);
                    }, 400);
                }

            }
        } catch (error) {
            setLoading(false);
        }
    }

    return(
        <div className="sc__main">
            <div className="container">
                <div className="u-fl u-fl-al-it-center u-mb--m">
                    {mode === 'cards' && (
                        <div onClick={()=>{
                            setMode('list');
                        }} className="btn btn--icon u-mr--xxl fadeIn animated">
                            <svg>
                                <use href="#icon-list"></use>
                            </svg>
                        </div>
                    )}

                    {mode === 'list' && (
                        <div onClick={()=>{
                            setMode('cards');
                        }} className="btn btn--icon u-mr--xxl fadeIn animated">
                            <svg>
                                <use href="#icon-cards"></use>
                            </svg>
                        </div>
                    )}

                    <div className="top-filter u-g--xl u-fl u-fl-wrap u-fl-al-it-center">
                        <div onClick={()=>{
                            setFilters({
                                ...filters,
                                finished: -1
                            });
                        }} className={'top-filter__it u-fl u-fl-al-it-center u-g--s u-cursor--pointer' + (filters.finished == -1 ? ' active' : '')}>
                            <div className="top-filter__it-dot">
                                <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="11" cy="11.5" r="10" stroke="black" strokeWidth="2"/>
                                </svg>
                            </div>
                            <div className="text text--sm u-text--upper">
                                Все проекты
                            </div>
                        </div>
                        <div onClick={()=>{
                            setFilters({
                                ...filters,
                                finished: 0
                            });
                        }} className={'top-filter__it u-fl u-fl-al-it-center u-g--s u-cursor--pointer' + (filters.finished == 0 ? ' active' : '')}>
                            <div className="top-filter__it-dot">
                                <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="11" cy="11.5" r="10" stroke="black" strokeWidth="2" fill="none"/>
                                </svg>
                            </div>
                            <div className="text text--sm u-text--upper">
                                в продаже
                            </div>
                        </div>
                        <div onClick={()=>{
                            setFilters({
                                ...filters,
                                finished: 1
                            });
                        }} className={'top-filter__it u-fl u-fl-al-it-center u-g--s u-cursor--pointer' + (filters.finished == 1 ? ' active' : '')}>
                            <div className="top-filter__it-dot">
                                <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="11" cy="11.5" r="10" stroke="black" strokeWidth="2"/>
                                </svg>
                            </div>
                            <div className="text text--sm u-text--upper">
                                реализованные
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'objects__area' + (loading ? ' loading' : '')}>
                    {mode === 'cards' && (
                        <div className="objects objects--cards fadeIn animated">
                            {objects.map((obj, i) => (
                                <div className="objects__it u-p--m">
                                    <a href="#" className="objects__it-img u-mb--m">
                                        <img src={obj.photo}/>
                                    </a>
                                    <div className="objects__it-title text text--m u-text--upper u-mb--xs">
                                        <a href="#" className="u-color--dark">
                                            {obj.name}
                                        </a>
                                    </div>
                                    <div className="u-mb--m">
                                        {obj.address}
                                    </div>
                                    <div className="tags u-fl u-fl-wrap u-g--xs">
                                        {obj.tags.map((tag, j) => (
                                            <div onClick={()=>{
                                                setFilters({
                                                  ...filters,
                                                  tags: filters.tags.includes(tag.term_id) ? filters.tags.filter(i => i !== tag.term_id) : [ ...filters.tags, tag.term_id ]
                                                });
                                            }} className={'tags__it u-fl u-fl-al-it-center u-px--s u-pb--xs' + (filters.tags.includes(tag.term_id) ? ' active' : '')}>
                                                {tag.name}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}

                    {mode === 'list' && (
                        <div className="objects objects--list fadeIn animated">
                            {objects.map((obj, i) => (
                                <div className="objects__it">
                                    <div className="row u-fl u-fl-wrap">
                                        <div className="col col-5 u-pt--s">
                                            <div className="objects__it-title u-font--light text text--large u-text--upper">
                                                <a href="#" className="u-color--dark">
                                                    {obj.name}
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col col-2 u-pt--r">
                                            <div className="u-pl--xl u-pos--relative u-zindex--2">
                                                {obj.address}
                                            </div>
                                        </div>
                                        <div className="col col-2 u-text--center u-pt--r u-pb--s u-pos--relative">
                                            <div className="objects__it-bg"/>
                                            <div className="tags u-fl u-fl-wrap u-g--xs">
                                                {obj.tags.map((tag, j) => (
                                                    <div className={'tags__it u-fl u-fl-al-it-center u-px--s u-pb--xs' + (filters.tags.includes(tag.id) ? ' active' : '')}>
                                                        {tag.name}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col col-3 objects__it-img-box">
                                        <a href="#" className="objects__it-img u-mb--m">
                                            <img src={obj.photo}/>
                                        </a>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Home;