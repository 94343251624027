function Header(){
    return(
        <header className="header">
            <div className="container">
                <div className="row u-fl u-fl-j-between">
                    <div className="col col-3">
                        <div className="u-fl u-g--xl">
                            <div className="text text--r link u-text--upper u-mr--m active">
                                <a href="#">проекты</a>
                            </div>
                            <div className="text text--r link u-text--upper u-mr--m">
                                <a href="#">о компании</a>
                            </div>
                        </div>
                    </div>
                    <div className="col col-2">
                        <div className="header__logo">
                            <svg width="540" height="92" viewBox="0 0 540 92" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M483.155 89.0917H491.27V47.8668H506.39L530.83 89.0917H540.038L514.75 47.3776C524.353 46.1546 536.987 41.6012 536.987 25.0435C536.987 2.22024 512.924 2.22024 506.032 2.22024H483.193V89.1105L483.155 89.0917ZM491.27 9.57712H508.461C525.539 9.57712 528.834 19.0225 528.834 25.0435C528.834 31.0645 525.52 40.5099 508.461 40.5099H491.27V9.57712ZM388.915 89.0917H444.047V81.7348H397.012V47.8668H439.264V40.5099H397.012V9.57712H442.07V2.20142H388.915V89.0917ZM304.956 9.57712H322.882C332.221 9.57712 341.805 13.9988 341.805 25.1564C341.805 36.314 332.597 40.4911 323.39 40.4911H304.975V9.57712H304.956ZM296.841 89.0917H304.956V47.8668H323.748C337.38 47.8668 349.92 40.8862 349.92 25.1564C349.92 6.99939 334.198 2.22024 321.902 2.22024H296.841V89.1105V89.0917ZM200.85 78.1787C207.591 87.6241 217.683 91.312 229.094 91.312C243.216 91.312 256.114 82.6003 256.114 66.8893C256.114 34.2443 211.3 49.9553 211.3 23.8017C211.3 12.7758 220.376 7.35689 230.826 7.35689C238.32 7.35689 243.724 10.3109 247.772 15.9556L254.758 10.913C248.883 3.17983 240.523 0 231.071 0C216.459 0 203.185 8.3353 203.185 23.8017C203.185 56.823 247.998 42.5796 247.998 66.8893C247.998 77.3132 239.393 83.9363 228.491 83.9363C219.886 83.9363 212.882 80.2672 208.589 73.0232L200.85 78.1787ZM32.4237 89.0917H40.5202L73.5653 2.20142H64.9792L36.7356 78.4233L8.71788 2.20142H0L32.4237 89.0917Z" fill="#020A0A"/>
                                <path d="M109.717 89.0919H164.849V81.7163H117.833V47.8482H160.085V40.4913H117.833V9.57736H162.891V2.20166H109.717V89.0919Z" fill="#020A0A"/>
                            </svg>

                        </div>
                    </div>
                    <div className="col col-3">
                        <div className="u-fl u-fl-al-it-center u-fl-j-end header__right">
                            <div className="text text--r link u-text--upper u-mr--xl">
                                <a href="#">подбор по параметрам</a>
                            </div>
                            <div className="u-fl u-g--sm">
                                <div className="btn btn--icon">
                                    <img src={require("../../assets/img/reload.svg").default}/>
                                </div>
                                <div className="btn btn--icon">
                                    <svg>
                                        <use href="#icon-heart"></use>
                                    </svg>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;