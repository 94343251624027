import { Routes, Route } from 'react-router-dom'
import React from 'react';
import Home from "./pages/Home";
import Header from "./components/layout/Header";

function App() {
  return (
      <div id="wrapper" className="wrapper">
          <Header/>
          <Routes>
              <Route path="/" element={<Home />} />
          </Routes>
      </div>
  );
}

export default App;
